@font-face {
  font-family: Aller;
  src: url("./Aller_Rg.ttf");
}

body {
  min-height: 100vh;
  background: url(./portada.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* compartir stilos 10 jul 2020 */
.compartir{
  display: none;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0;
}
.compartir a{
  text-decoration: none;
}
.compartir img{
  width: 45px;
}
.compartir__content{
  text-align: center;
  color: #7c7c7c;
  font-weight: normal;
}
.compartir__content p{
  margin-top: 5px;
  line-height: 1.5rem;
}

/*  */
.datos__cotacto{
  /* padding: 0 70px; */
  padding-left: 30px !important;
  padding-right: 30px;
}
/*  */

.icono + a {
  color: black;
  font-size: 3vh;
}

[class*="retrato"] {
  display: block;

  position: relative;

  border: solid 0 transparent;
  border-radius: 100%;

  margin-left: auto;
  margin-right: auto;

  width: 30%;
  min-width: 140px;
  max-width: 300px;

  background-color: #fff;

  box-shadow: 0 2px 2px 0 gray, 0 0 0 10px white, 0 0 0 18px #f9a519,
    0 2px 10px 20px gray;
  z-index: 3;
}

/* cambios */
.web_mobile{
  /* text-align: center; */
  /* transform: translateY(10px); */
  /* display: none; */
}
.web_mobile a{
  color: #000;
  /* font-size: 2rem; */
}

.retrato-contact,
.retrato-ejecutiva,
.retrato-empresarial,
.retrato-corporativa {
  box-shadow: 0 2px 2px 0 gray, 0 0 0 10px white, 0 0 0 18px #d1d3d4,
    0 2px 10px 20px gray;
}

.contenedor {
  background-color: rgba(255, 255, 255, 0.95);
  position: relative;
  top: 0;
  width: 80%;
  box-shadow: 0 0 5px 5px rgba(155, 155, 155, 0.8);
}

[class*="gradiente-horizontal"] {
  position: relative;
  width: 100%;
  height: 24px;
  background: linear-gradient(to right, #f4831f, #faac18);
}

.gradiente-horizontal-contact,
.gradiente-horizontal-ejecutiva,
.gradiente-horizontal-empresarial,
.gradiente-horizontal-corporativa {
  background: #bcbec0;
}

[class*="gradiente-horizontal-bottom"] {
  position: relative;
  width: 100%;
  height: 24px;
  background: linear-gradient(to right, #f4831f, #f9a519);
}

.gradiente-horizontal-bottom-contact,
.gradiente-horizontal-bottom-ejecutiva,
.gradiente-horizontal-bottom-empresarial,
.gradiente-horizontal-bottom-corporativa {
  background: #bcbec0;
}

[class*="gradiente-horizontal-intermedio"] {
  position: relative;
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 100%;
  height: 1vh;
  background: linear-gradient(to right, #f4831f, #faac18);
}

.gradiente-horizontal-intermedio-contact,
.gradiente-horizontal-intermedio-ejecutiva,
.gradiente-horizontal-intermedio-empresarial,
.gradiente-horizontal-intermedio-corporativa {
  background: #bcbec0;
}

#nombrePersonal {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3vh;
  font-size: 5.5vh;
}

#primer-nombre {
  font-family: Aller;
  font-weight: bold;
  display: inline-block;
  /* color: black; */
  /* margin-bottom: -1.7vh; */
  font-size: 4.5vh;
  color: #7C7C7C;
}

[id*="primer-apellido"] {
  display: inline;
  color: #f4831f;
}

#primer-apellido-contact,
#primer-apellido-ejecutiva,
#primer-apellido-empresarial,
#primer-apellido-corporativa {
  /* color: #222; */
  color: #7C7C7C;
  font-size: 4.5vh;
  font-family: Aller;
  font-weight: bold;
}

#cargo,
#cargo2 {
  text-align: center;
  font-size: 2.5vh;
  margin-top: 0.5vh;
  font-family: Aller;
  color: #7C7C7C;
}

.nombreYlogo {
  text-align: center;
}

#logo-tarjeta {
  margin-top: 10px;
  position: relative;
  width: 40%;
  max-width: 300px;
}

#sitioWeb {
  color: #000;
  font-size: 2.5vh;
  display: none;
}

.documentos {
  text-align: center !important;
  font-size: 2rem;
}

.documentos > a {
  color: black;
}

.desktop {
  display: none;
}

h1.seccion {
  text-align: center;
  font-size: 4rem;
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
}

h1.seccion::first-letter {
  font-size: 1.5em;
}

.form-name {
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
  color: #f4831f;
  margin-left: 10%;
}

.form-name::first-letter {
  font-size: 1.5em;
}

div.form-row {
  display: flex;
}

div.form-col > a > button {
  color: black;
  width: 90%;
  border: none;
  box-shadow: 2px 2px 0 0 #999, 0 2px 0 0 #999, 2px 0 0 0 #999,
    -2px -2px 0 0 #ddd, 0 -2px 0 0 #ddd, -2px 0 0 0 #ddd, 0 0 0 2px #ddd;
  text-transform: uppercase;
  font-weight: 600;
}

div.form-col > a > button::first-letter {
  font-size: 1.2em;
}

div.form-col > input {
  position: relative;
  right: 0;
  width: 80%;
}

div.form-col {
  flex-direction: column;
  flex: 1;
}

[class$="-col"] > img,
.centrado > img {
  padding-top: 50px;
}

/* =================================
            SUBRAYADO
================================= */

span.cuadrito1,
span.cuadrito2 {
  border: solid 5px #f9a519;
}

span.cuadrito1 {
  left: 0;
}

span.cuadrito2 {
  right: 0;
}

span.subrayado {
}

.contacto > div {
  text-align: left;
  margin-right: 5px;
  padding-left: 40px;
  /* text-align: center; */
}
.contacto > div > input {
}

/*=================================
              ICONOS
=================================*/

.icono {
  position: relative;
  margin: 5px;
  width: 23px;
  height: 23px;
  object-fit: contain;
}

.compartir-contacto {
  width: 5vh;
  height: 5vh;
}
.descargar-contacto {
  width: 6vh;
  height: 6vh;
}

[class$="-flotante"] {
  position: fixed;
  right: 8px;
  width: 45px;
  height: 45px;

  /* background: white; */
  /* border: solid 2px #f4831f; */
  border-radius: 5px;
  z-index: 1099;
}

[class$="-flotante"]:hover {
  transform: scale(1.1);
  filter: drop-shadow(0 0 20px #f9a519);
}

.compartir-contacto-flotante {
  top: 20%;
}
.descargar-contacto-flotante {
  top: calc(20% + 55px);
}

.icono.grande {
  width: 50px;
  height: 50px;
}

.icono.grande:hover {
  filter: sepia(1);
}

.icono.grande:active {
  filter: invert(100%);
}

.renglon-ubicacion{
  display: flex;
}
.renglon-ubicacion a{
  margin-top: -5px;
}
/* .contacto_center{
  display: flex;
} */

.renglon-telefono,
.renglon-mobile,
.renglon-ubicacion,
.renglon-mail,
.renglon-documento {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 8px;
  /* font-weight: lighter; */
}
.renglon-telefono a,
.renglon-mobile a,
.renglon-ubicacion a,
.renglon-mail a,
.renglon-documento a{
  text-decoration: none;
  margin-left: 10px;
  color: #262626;
}

.renglon-webpersonal {
  margin-left: 45px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.webpersonal {
  width: 35px;
  height: 35px;
}

.webpersonal + a {
  margin-left: -10px;
}

.social-icons {
  position: relative;
  margin: 30px;
}

.vcard-download {
}

.vcard-link {
}

.vcard-logo {
}

.vcard-download {
  background-color: #fff;
  border-radius: 5px;
  width: auto;
}

.vcard-download:hover,
.vcard-download:active {
  background-color: #ddd;
}

.vcard-link {
  padding-right: 10px;
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  vertical-align: middle !important;
  text-shadow: none;
  position: relative;
}

.vcard-link:hover,
.vcard-link:active {
  color: #000;
  text-decoration: none;
}

/* =================================
              QR
================================= */

.qr-button {
  position: fixed;
  top: 20%;
  right: 0;
  width: 50px;
  height: 50px;
  background: white;
  border: solid 2px black;
  border-radius: 100%;
}

.qr-button:hover {
  transform: scale(1.1);
}

.qr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1100;
  background: black;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.5);
}

#qr-toggle:checked ~ .qr-overlay {
  pointer-events: auto;
  opacity: 0.9;
  transform: scale(1.1);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.qr-image {
  position: fixed;
  top: 40%;
  left: 50%;
  z-index: 1110;
  width: 80%;
  max-width: 400px;
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
}

.qr-image.close-modal{
  /* position: absolute; */
  top: 40px;
  z-index: 1120;
  left: auto;
  right: -10px;
  transform: translateX(50px);
  width: 45px;
}

.qr-share-buttons {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 10%;
  left: 50%;
  z-index: 1110;
  width: 90%;
  max-width: 640px;
  /* height: 28px; */
  pointer-events: none;
  opacity: 0;
}

[class$="-share"] {
  margin-left: 3px;
  margin-right: 3px;
  height: 40px;
  width: 75px;
  max-width: 20vw;
  padding-top: 8px;
}

.qr-fb-share {
  background-color: #4080ff;
  border-radius: 5px;
}

.qr-tw-share {
  background-color: #1da1f2;
  border-radius: 5px;
}

.qr-fb-share:hover,
.qr-fb-share:active {
  background-color: #3070ff;
}

.qr-tw-share:hover,
.qr-tw-share:active {
  background-color: #0d91e2;
}

.qr-fb-logo,
.qr-tw-logo,
.qr-wa-logo,
.qr-mail-logo {
  max-height: 20px !important;
  margin-left: 5px;
  margin-right: 2px;
  width: 20px;
  height: 20px;
}

/* .qr-tw-logo {
  color: #fff;
} */

svg {
  fill: red;
}

.qr-tw-logo {
}

.qr-mail-logo {
  width: 18px;
  height: 18px;
}

.qr-fb-link,
.qr-tw-link,
.qr-wa-link,
.qr-mail-link {
  /* padding-right: 5px; */
}

.qr-mail-link {
  /* padding-left: 5px; */
}

.qr-fb-link,
.qr-tw-link {
  color: #fff;
  /* padding-right: 10px; */
  font-size: 15px;
  font-family: Helvetica, Arial, sans-serif;
  vertical-align: middle !important;
  text-shadow: none;
  position: relative;
}

.qr-fb-link:hover,
.qr-tw-link:hover,
.qr-fb-link:active,
.qr-tw-link:active {
  color: #fff;
  text-decoration-line: none;
}

.qr-tw-share {
  /* position: fixed;
  bottom: 10%;
  left: 65%;
  z-index: 1110;
  width: 100%;
  max-width: 640px;
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5); */
}

.qr-wa-share {
  background-color: #25d366;
  border-radius: 5px;
}

.qr-wa-share:hover,
.qr-wa-share:active {
  background-color: #25c366;
}

.qr-wa-link {
  color: #fff;
  /* padding-right: 10px; */
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  vertical-align: middle !important;
  text-shadow: none;
  position: relative;
}

.qr-wa-link:hover,
.qr-wa-link:active {
  color: #fff;
  text-decoration: none;
}

.qr-mail-share {
  background-color: #fff;
  border-radius: 5px;
}

.qr-mail-share:hover,
.qr-mail-share:active {
  background-color: #ddd;
}

.qr-mail-link {
  color: #000;
  /* padding-right: 10px; */
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  vertical-align: middle !important;
  text-shadow: none;
  position: relative;
}

.qr-mail-link:hover,
.qr-mail-link:active {
  color: #f4831f;
  text-decoration: none;
}

#qr-toggle:checked ~ label > .qr-image,
#qr-toggle:checked ~ label > .qr-fb-share,
#qr-toggle:checked ~ label > .qr-tw-share,
#qr-toggle:checked ~ label > .qr-wa-share,
#qr-toggle:checked ~ label > .qr-mail-share,
#qr-toggle:checked ~ label > .qr-share-buttons {
  pointer-events: auto;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);
  transition: transform 0.5s ease, opacity 0.5s ease;
  transition-delay: 0.1s;
}

/* =================================
            NAVBAR
================================= */

.testing-navbar {
  height: 5.6vh;
  background-image: linear-gradient(193deg, #ebebeb, #d2d2d2);
  border: none;
  margin: 0;
}

.testing-navbar ~ container {
  height: 5.6vh;
}

.navbar-nav > li > a {
  font-family: Aller;
  font-size: 2vh;
  font-weight: bold;
  color: #5e5e5d;
  text-transform: uppercase;
}

.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
  color: #f4831f !important;
}

/* =================================
              WIDTH
================================= */

.width-70 {
  width: 70%;
}

.centrado {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

div[class*="renglon"] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div[class*="renglon"]:hover {
  white-space: unset;
  overflow: visible;
}

div.renglon-ubicacion {
  white-space: unset;
  overflow: visible;
}

/* =================================
              Perfil
              Giro
              Productos
              Servicios
              MiPanel
              Reconocimientos
              Clientes
              Proyectos
              Testimonios
              Galeria
  ================================= */

h1.perfil,
h1.giro,
h1.productos,
h1.servicios,
.proyectos > h3,
.testimonios > h3,
.linea2 {
  color: #f4831f;
  font-weight: bold;
  font-size: 3.3vh;
  text-align: center;
  text-transform: uppercase;
}

.linea2 {
  font-style: italic;
  color: #ff6c00;
}

h1.perfil::first-letter,
h1.giro::first-letter,
h1.productos::first-letter,
h1.servicios::first-letter,
.proyectos > h3::first-letter,
.testimonios > h3::first-letter {
  font-size: 1.5em;
}

div[class*="-row"] {
  margin: 20px;
}

p.perfil,
p.giro {
}

.miPanel > h3,
p.linea1,
.fecha {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.miPanel > h3::first-letter,
.proyectos > h3::first-letter {
  font-size: 1.2em;
}

.proyectos > h3,
.testimonios > h3,
p.linea1 {
  font-style: italic;
}

p.testimonio {
  font-style: italic;
  text-align: center;
}

p.descripcion {
  font-style: italic;
  text-align: center;
  font-size: 2.7vh;
  font-weight: 600;
}

div.reconocimientos-col > div.linea2 {
  background: #c7c8ca;
}

div.reconocimientos-col > div.fecha {
  background: #e6e7e8;
}

div.reconocimientos-row {
  background: #e6e7e8;
  font-size: 3.3vh;
}

.clientes,
.galeria {
  display: flex;
  flex-wrap: wrap;
}

.flex-contenedor {
}

.clientes > div,
.galeria > div {
  text-align: center;

  margin-left: auto;
  margin-right: auto;
}

.clientes > div > a {
}

.clientes > div > a > img,
.galeria > div > img {
  margin: 10px;
}

/*=================================
                  MAP
=================================*/

.mapa {
  position: relative;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  border: solid white 5px;
  box-shadow: 0 0 5px 5px rgba(155, 155, 155, 0.5);
}

/*=================================
            Non-Desktop
=================================*/

@media screen and (max-width: 1279px) {
  /* este es el fondo negro */
  .testing-navbar {
    border: none;
    margin: 0;
    background: rgba(255, 255, 255, 0);
  }

  .navbar-toggle + .navbar-collapse.collapse.in {
    display: block;
  }

  .navbar-toggle.collapsed + .navbar-collapse.collapse {
    display: none;
  }

  .navbar-collapse > ul.nav.navbar-nav > li {
    float: none;
  }

  .navbar-collapse {
    background: linear-gradient(45deg, #d2d2d2 0%, #ebebeb 100%);
  }

  .navbar-toggle,
  .navbar-toggle:active {
    display: block;
    background: #f4831f !important;
  }

  .navbar-toggle:hover {
    background: #f9a519 !important;
  }

  .contenedor {
    margin-left: auto;
    margin-right: auto;
  }

  .hemi + .contenedor {
    top: calc(-15vw + 25px);
  }

  #nombrePersonal.inicio {
    margin-top: calc(15vw + 5px);
  }

  [class*="retrato"] {
    top: 25px;
  }

  .non-desktop-gray-gradient {
    margin-top: 5px !important;
    background: linear-gradient(to top right, #d2d2d2 50%, #ebebeb 70%);
  }

  .miPanel-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .panelPar {
    width: 100%;
    border: solid 0 #d2d2d2;
    border-top-width: 5px;
    border-bottom-width: 5px;
    background: #d2d2d2;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1279px) {
  .hemi + .contenedor {
    top: -125px;
  }

  #nombrePersonal.inicio {
    margin-top: 155px;
  }
}

@media screen and (max-width: 666px) {
  /* yoshimar */
  .hemi + .contenedor {
    top: -75px;
  }

  #nombrePersonal.inicio {
    margin-top: 105px;
  }
  /* cambios */

  .contacto > div {
    padding-left: 0;
  }

  .web_mobile{
    display: block;
  }
  /* #sitioWeb{
    display: none;
  } */
  .renglon-telefono a,
.renglon-mobile a,
.renglon-ubicacion a,
.renglon-mail a,
.renglon-documento a{
  font-size: 2rem;
  line-height: 2rem;
}

.compartir{
  display: flex;
}
.compartir-contacto-flotante, .descargar-contacto-flotante {
  display: none;
}

}

/*=================================
                TABLET
=================================*/

@media screen and (min-width: 768px) {
  /* [class*="gradiente-horizontal"] {
    height: 30px;
  } */

  [class*="gradiente-horizontal-intermedio"] {
    height: 1vh;
  }
}

/*=================================
                DESKTOP
=================================*/

@media screen and (min-width: 1280px) {
  .desktop {
    display: inherit;
  }

  .app {
    position: relative;
    top: 15vh;
  }

  .contenedor {
    margin-left: 218px;
    margin-right: 50px;
    width: auto;
    height: 50%;
    position: relative;
  }

  .container {
    width: auto;
  }

  [class*="gradiente-horizontal"] {
    height: 24px;
  }

  [class*="gradiente-horizontal-intermedio"] {
    height: 1vh;
  }

  .hide-in-desktop {
    display: none;
  }

  [class*="retrato"] {
    position: absolute;
    top: 50%;
    left: 50px;
    width: 300px;
    transform: translate(0, -50%);
  }

  .hemi {
    position: absolute;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0) 50%
    );
    border: solid 0 transparent;
    border-radius: 100%;
    height: 400px;
    width: 400px;

    top: 50%;
    left: 18px;
    transform: translate(0, -50%);
    z-index: 5;

    box-shadow: -4px 0 1px 0 rgba(155, 155, 155, 0.5);
  }

  #nombrePersonal {
    margin-top: auto;
  }

  #nombrePersonal.inicio {
    margin-top: 3vh;
    font-size: 6vh;
  }

  .nombreYlogo {
    flex: 0.4;
  }

  .nombreLogoContacto {
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    /* padding-left: 170px; */
    margin-left: 170px;
  }

  .contacto {
    flex: 1;
  }

  .contacto.inicio {
    max-width: 40vw;
  }

  .col {
    flex: 0.3;
  }

  #logo-tarjeta {
    width: 100%;
    max-width: 200px;
  }

  .mapa {
    max-width: 600px !important;
  }

  .social-icons {
    margin: 0;
    padding: 3rem 0;
    /* display: flex; */
  }

  div.social-icons a {
    flex: 1;
  }

  /* navbar */
  .testing-navbar {
    position: relative;
    border: none;
    margin: 0;
  }

  div[class*="-row"] {
    display: flex;
  }

  div[class$="-col"] {
    margin: 10px;
    flex-direction: column;
    flex: 1;
  }

  div.miPanel {
    background: #e6e7e8;
    border: solid 5px #e6e7e8;
  }

  .reconocimientos-row {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 50vw;
  }
  .reconocimientos-col {
    margin: 0 !important;
    width: 50%;
    position: relative;
    display: table;
    text-align: center;
  }

  .reconocimientos-col > .linea2,
  .reconocimientos-col > .fecha {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 18vh;
    margin: auto;
  }
}
